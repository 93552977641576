body {
  margin: 0;
  font-family: Quicksand,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}

:root {

  --background-color:#f4f9fe;
  --primary-color:#4265ec;
  --text-color:#2b313b;
  --text-light-color:#404959;
  --text-light-color-1:#566276;
  --sidebar-active-color:#fff;
  --sidebar-inactive-color:#b4b6bd;
  --border-color:#e8f2fd;
  --red-color:#ff6347;
  --sidebar-background-color:#fff;
  --button-color:#5c678a;
  --button-hover-color:#667299;
  --active-color:#525b7a;

}

/*
 --button-color:#0070d2;
  --button-hover-color:#198cf0;
  --active-color:#0070d2;
*/

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}