
.login-input {
    background-color: #fff !important;
}

.login-input:focus {
    outline:none;
    background-color: #fff;

}

.login-input::placeholder {
    font-size: 14px;
}